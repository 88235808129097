import { Industry, IndustryCategory, IndustrySubCategory } from '@mayple/types';

import { industriesLabelsLookup } from './index';

import { pairsToLabelLookup } from '../../app/enums';
import { LabelValueProps } from '../../app/types';

interface Option extends LabelValueProps {
  [x: string]: unknown; //  string | boolean
  label: string;
  value: string | null;
  category?: string;
  categoryLabel?: string;
  deprecated?: boolean;
}

type GroupedEnum = {
  [x: string]: any;
  label: string;
  category: string;
  value: string;
  options: Option[];
};

type GroupedEnums = Array<GroupedEnum> | GroupedEnum[];

/**
 * Will create an enums that can be used for selecting values part of a category group.
 * For example: { label: 'Some label.', value: 'someValue', category: 'categoryXyz' } is a single enum option.
 */
export const createGroupedEnum = (
  categoriesLabels: Record<IndustryCategory, string>,
  subCategories: Record<IndustryCategory, Partial<Record<IndustrySubCategory, string>>>,
  deprecatedSubCategories: Set<IndustrySubCategory>,
  categoryKey = 'categoryName',
): GroupedEnums => {
  const enums: GroupedEnums = [];

  Object.keys(categoriesLabels).forEach((cKey) => {
    const options: Option[] = [];

    Object.entries(subCategories[cKey as IndustryCategory]).forEach(([key, value]) => {
      options.push({
        value: key,
        label: value,
        [categoryKey]: cKey,
        category: cKey,
        deprecated: deprecatedSubCategories.has(key as IndustrySubCategory),
      });
    });

    enums.push({
      label: categoriesLabels[cKey as IndustryCategory],
      category: cKey,
      value: cKey,
      [categoryKey]: cKey,
      options,
    });
  });

  // for (const cKey of Object.keys(categoriesLabels)) {
  //   const category = Object.entries(subCategories[cKey]);
  //   const options = [];
  //
  //   for (const [key, value] of category) {
  //     options.push({
  //       value: key,
  //       label: value,
  //       [categoryKey]: cKey,
  //       category: cKey,
  //       deprecated: deprecatedSubCategories.has(key),
  //     });
  //   }
  //
  //   enums.push({
  //     label: categoriesLabels[cKey],
  //     category: cKey,
  //     value: cKey,
  //     [categoryKey]: cKey,
  //     options,
  //   });
  // }

  return enums;
};

export const createCategorizedEnum = (
  categoriesLabels: Record<IndustryCategory, string>,
  subCategories: Record<IndustryCategory, Partial<Record<IndustrySubCategory, string>>>,
  deprecatedSubCategories: Set<IndustrySubCategory>,
  categoryKey = 'categoryName',
) => {
  const enums: Option[] = [];

  Object.entries(categoriesLabels).forEach(([cKey, cLabel]) => {
    Object.entries(subCategories[cKey as IndustryCategory]).forEach(([key, value]) => {
      enums.push({
        value: key,
        label: value,
        [categoryKey]: cKey,
        category: cKey,
        categoryLabel: cLabel,
        deprecated: deprecatedSubCategories.has(key as IndustrySubCategory),
      });
    });
  });

  // for (const [cKey, cLabel] of Object.entries(categoriesLabels)) {
  //   const category = Object.entries(subCategories[cKey]);
  //
  //   for (const [key, value] of category) {
  //     enums.push({
  //       value: key,
  //       label: value,
  //       [categoryKey]: cKey,
  //       category: cKey,
  //       categoryLabel: cLabel,
  //       deprecated: deprecatedSubCategories.has(key),
  //     });
  //   }
  // }

  return enums;
};

export const createFlatCategoriesEnum = (
  categoriesLabels: Record<IndustryCategory, string>,
  subCategories: Record<IndustryCategory, Partial<Record<IndustrySubCategory, string>>>,
  deprecatedSubCategories: Set<IndustrySubCategory>,
  categoryKey = 'categoryName',
) => {
  const enums: Option[] = [];

  Object.keys(categoriesLabels).forEach((cKey) => {
    enums.push({
      label: categoriesLabels[cKey as IndustryCategory],
      value: null,
      category: cKey,
      [categoryKey]: cKey,
    });

    Object.entries(subCategories[cKey as IndustryCategory]).forEach(([key, value]) => {
      enums.push({
        value: key,
        label: value,
        [categoryKey]: cKey,
        category: cKey,
        deprecated: deprecatedSubCategories.has(key as IndustrySubCategory),
      });
    });
  });

  // for (const cKey of Object.keys(categoriesLabels)) {
  //   const category = Object.entries(subCategories[cKey]);
  //
  //   enums.push({
  //     label: categoriesLabels[cKey],
  //     category: cKey,
  //     [categoryKey]: cKey,
  //   });
  //
  //   for (const [key, value] of category) {
  //     enums.push({
  //       value: key,
  //       label: value,
  //       [categoryKey]: cKey,
  //       category: cKey,
  //       deprecated: deprecatedSubCategories.has(cKey),
  //     });
  //   }
  // }

  return enums;
};

/**
 * Same as pairsToLabelLookup, but for grouped enums that are created by function createGroupedEnum().
 */
export const groupedPairsToLabelLookup = (groups: GroupedEnums, groupsLabels: Record<IndustryCategory, string>) => {
  let labels = {};

  groups.forEach((group) => {
    const { options } = group;
    labels = { ...labels, ...pairsToLabelLookup(options) };
  });

  Object.entries(groupsLabels).forEach(([value, label]) => {
    // @ts-ignore
    labels[value] = label;
  });

  // for (const group of groups) {
  //   const { options } = group;
  //   labels = { ...labels, ...pairsToLabelLookup(options) };
  // }
  //
  // for (const [value, label] of Object.entries(groupsLabels)) {
  //   labels[value] = label;
  // }

  return labels;
};

const getSubCategoryFromIndustry = (industry: Industry | Array<Industry> | null | undefined): string | null => {
  const industrySubCategory = Array.isArray(industry)
    ? industry?.[0]?.industrySubCategory
    : industry?.industrySubCategory;

  if (industrySubCategory != null) {
    return industriesLabelsLookup?.[industrySubCategory];
  }

  return null;
};

const getCategoryFromIndustry = (
  industry: Industry | Array<Industry> | null | undefined,
): IndustryCategory | string => {
  let industryCategory: IndustryCategory | null | undefined = null;

  if (Array.isArray(industry)) {
    if (+industry.length > 0) {
      industryCategory = industry?.[0]?.industryCategory;
    }
  } else {
    industryCategory = industry?.industryCategory;
  }

  if (industryCategory != null) {
    return industriesLabelsLookup?.[industryCategory];
  }

  return 'SERVICES_OTHER_1020';
};

export const getIndustryLabel = (
  industry: Industry[] | Industry | string | null | undefined,
  showFullLabel = false,
) => {
  if (typeof industry === 'string') {
    return industriesLabelsLookup?.[industry as IndustryCategory];
  }

  const industrySubCategory = getSubCategoryFromIndustry(industry);
  const industryCategory = getCategoryFromIndustry(industry);

  if (showFullLabel) {
    return [industryCategory, industrySubCategory].filter(Boolean).join(' / ');
  }

  return industrySubCategory || industryCategory;
};
